var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"allbo"},[_c('div',{staticClass:"auto"},[_c('div',{staticClass:"tit"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('ul',[_c('li',{class:_vm.indexOf == 0 ? 'active' : '',on:{"click":function($event){_vm.indexOf = 0}}},[_vm._v(" 首页 ")]),_c('li',{class:_vm.indexOf == 1 ? 'active' : '',on:{"click":function($event){_vm.indexOf = 1}}},[_vm._v(" 公司简介 ")]),_c('li',{class:_vm.indexOf == 2 ? 'active' : '',on:{"click":function($event){_vm.indexOf = 2}}},[_vm._v(" 产品介绍 ")])])])]),(_vm.indexOf == 0)?_c('div',{staticClass:"midbox"},[_vm._m(1),_vm._m(2)]):_vm._e()]),(_vm.indexOf == 1)?_c('div',{staticClass:"midbox2"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e(),(_vm.indexOf == 2)?_c('div',{staticClass:"midbox3"},[_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"footbutton"},[_c('div',{staticClass:"auto"},[_vm._m(10),_c('div',{staticClass:"rightx"},[_vm._m(11),_c('div',{staticClass:"mox onem",on:{"click":_vm.goNote}},[_vm._v("用户协议")]),_c('div',{staticClass:"mox twom",on:{"click":_vm.goPrivacy}},[_vm._v("隐私协议")])])])])]):_vm._e(),_c('div',{staticClass:"footer"},[_vm._v(" 成都和展科技有限公司 版权所有 "),_c('span',{on:{"click":_vm.gotolink}},[_vm._v("蜀ICP备2021030356号-3")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../assets/img/icon.png"),"alt":""}}),_vm._v(" 和展科技 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../assets/img/高效科技装修工地 管理平台.png"),"alt":""}}),_c('div',{staticClass:"botbox"},[_c('div',{staticClass:"leto"},[_vm._v("B端后台")]),_c('div',{staticClass:"rito"},[_c('a',{attrs:{"href":"http://dow.hezkj.top/android/hzkj.apk"}},[_vm._v("APP下载 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{staticClass:"appx",attrs:{"src":require("../assets/img/APP.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/PC端.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("../assets/img/公司简介banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mido"},[_c('div',{staticClass:"auto"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../assets/img/公司环境.png"),"alt":""}})]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/文字1.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fobuto"},[_c('img',{attrs:{"src":require("../assets/img/历时3年  重拳铲除行业痛点难题.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mapbox"},[_c('div',{staticClass:"titop"},[_c('div',{staticClass:"auto"},[_c('h2',[_vm._v("行业成功经验")]),_c('div',{staticClass:"txt"},[_vm._v(" 走访全国 "),_c('span',{staticClass:"colo"},[_vm._v(" 1000")]),_vm._v(" 余家头部装企，调研全国99城市、3000组在建工地 "),_c('div',[_vm._v(" 筛选全国 "),_c('span',{staticClass:"colo"},[_vm._v(" 5000")]),_vm._v("份业主问卷反馈 ")])])])]),_c('div',{staticClass:"map"},[_c('img',{attrs:{"src":require("../assets/img/分散图.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top3"},[_c('img',{attrs:{"src":require("../assets/img/banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"midob"},[_c('div',{staticClass:"auto"},[_c('h2',[_vm._v("高效科技装修工地管理平台")]),_c('img',{attrs:{"src":require("../assets/img/PC端2.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mibbtn"},[_c('img',{attrs:{"src":require("../assets/img/腰线.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loef"},[_c('img',{attrs:{"src":require("../assets/img/文字2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bod"},[_c('img',{attrs:{"src":require("../assets/img/APP2.png"),"alt":""}})])
}]

export { render, staticRenderFns }