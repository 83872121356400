<template>
  <div>
    <div class="allbo">
      <div class="auto">
        <div class="tit">
          <div class="left">
            <img src="../assets/img/icon.png" alt="" /> 和展科技
          </div>
          <div class="right">
            <ul>
              <li @click="indexOf = 0" :class="indexOf == 0 ? 'active' : ''">
                首页
              </li>
              <li @click="indexOf = 1" :class="indexOf == 1 ? 'active' : ''">
                公司简介
              </li>
              <li @click="indexOf = 2" :class="indexOf == 2 ? 'active' : ''">
                产品介绍
              </li>
            </ul>
          </div>
        </div>
        <div class="midbox" v-if="indexOf == 0">
          <div class="left">
            <img src="../assets/img/高效科技装修工地 管理平台.png" alt="" />
            <div class="botbox">
              <div class="leto">B端后台</div>
              <div class="rito">
                <a href="http://dow.hezkj.top/android/hzkj.apk">APP下载 </a>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="../assets/img/APP.png" alt="" class="appx" />
            <img src="../assets/img/PC端.png" alt="" />
          </div>
        </div>
      </div>
      <div class="midbox2" v-if="indexOf == 1">
        <div class="top">
          <img src="../assets/img/公司简介banner.png" alt="" />
        </div>
        <div class="mido">
          <div class="auto">
            <div class="left">
              <img src="../assets/img/公司环境.png" alt="" />
            </div>
            <div class="right">
              <img src="../assets/img/文字1.png" alt="" />
            </div>
          </div>
        </div>
        <div class="fobuto">
          <img src="../assets/img/历时3年  重拳铲除行业痛点难题.png" alt="" />
        </div>

        <div class="mapbox">
          <div class="titop">
            <div class="auto">
              <h2>行业成功经验</h2>
              <div class="txt">
                走访全国
                <span class="colo"> 1000</span>
                余家头部装企，调研全国99城市、3000组在建工地
                <div>
                  筛选全国 <span class="colo"> 5000</span>份业主问卷反馈
                </div>
              </div>
            </div>
          </div>
          <div class="map">
            <img src="../assets/img/分散图.png" alt="" />
          </div>
        </div>
      </div>

      <div class="midbox3" v-if="indexOf == 2">
        <div class="top3">
          <img src="../assets/img/banner.png" alt="" />
        </div>
        <div class="midob">
          <div class="auto">
            <h2>高效科技装修工地管理平台</h2>

            <img src="../assets/img/PC端2.png" alt="" />
          </div>
        </div>
        <div class="mibbtn">
          <img src="../assets/img/腰线.png" alt="" />
        </div>

        <div class="footbutton">
          <div class="auto">
            <div class="loef">
              <img src="../assets/img/文字2.png" alt="" />
            </div>
            <div class="rightx">
              <div class="bod">
                <img src="../assets/img/APP2.png" alt="" />
              </div>
              <div @click="goNote" class="mox onem">用户协议</div>
              <div @click="goPrivacy" class="mox twom">隐私协议</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        成都和展科技有限公司 版权所有
        <span @click="gotolink">蜀ICP备2021030356号-3</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GmHome",

  data() {
    return {
      indexOf: 0,
    };
  },

  mounted() {},

  methods: {
    goNote(){
      this.$router.push('./notice')
    },
    goPrivacy(){
      // window.open('./privacy')
      this.$router.push('./privacy')
    },
    gotolink() {
      //
      // window.location.href="https://beian.miit.gov.cn/#/Integrated/index"
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
};
</script>

<style lang="less" scoped>
.allbo {
  background: #00bf9f;
  height: 100vh;
  .auto {
    overflow: hidden;
    width: 70%;
    margin: auto;
    .tit {
      padding-top: 20px;
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      .left {
        display: flex;
        align-items: center;
        img {
          // width: 32px;
          // height: 32px;
          margin-right: 10px;
        }
      }
      .right {
        ul {
          display: flex;
          li {
            margin-left: 20px;
            cursor: pointer;
            padding-bottom: 8px;
          }
          .active {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
    .midbox {
      margin-top: 220px;
      display: flex;
      justify-content: space-between;
      .left {
        margin-top: 70px;
        margin-right: 70px;
      }
      .botbox {
        margin-top: 50px;
        display: flex;
        .leto {
          text-align: center;
          line-height: 61px;
          width: 211px;
          color: #00bf9f;
          margin-right: 28px;
          height: 61px;
          background: url("../assets/img/B端平台.png");
        }
        .rito {
          cursor: pointer;
          text-align: center;
          line-height: 61px;
          width: 211px;
          color: #fff;
          height: 61px;
          background: url("../assets/img/APP下载.png");
          /*包含以下五种的链接*/
          a {
            color: #fff;
            text-decoration: none;
          }
          /*正常的未被访问过的链接*/
          a:link {
            text-decoration: none;
          }
          /*已经访问过的链接*/
          a:visited {
            text-decoration: none;
          }
          /*鼠标划过(停留)的链接*/
          a:hover {
            text-decoration: none;
          }
          /* 正在点击的链接，鼠标在元素上按下还没有松开*/
          a:active {
            text-decoration: none;
          }
          /* 获得焦点的时候 鼠标松开时显示的颜色*/
          a:focus {
            text-decoration: none;
          }
        }
      }
      .right {
        // display: flex;
        position: relative;
        .appx {
          position: absolute;
          bottom: 0;
          left: -47px;
        }
      }
    }
  }
  .midbox2 {
    img {
      width: 100%;
    }
    .top {
      background: #fff;
    }
    .fobuto {
      background: #fff;
    }
    .mapbox {
      background: #fff;
      // padding-bottom: 20px;
      .titop {
        padding-top: 100px;
        margin: auto;
        text-align: center;
        margin-bottom: 30px;
        h2 {
          font-size: 37.52px;
          font-weight: 600;
          margin-bottom: 27px;
        }
        .txt {
          font-size: 20px;
          .colo {
            color: #00bf9f;
          }
        }
      }
      .map {
        width: 55%;
        padding-bottom: 80px;
        display: flex;
        margin: auto;
        justify-content: center;
      }
    }

    .mido {
      padding: 100px 0px;
      width: 100%;
      display: flex;
      background: #fff;
      .auto {
        display: flex;
        justify-content: space-between;
      }
      .left {
        // margin-top: 100px;
        // margin-bottom: 100px;
      }
    }
  }

  .midbox3 {
    img {
      width: 100%;
    }
    .top3,
    .mibbtn {
      background: #fff;
    }
    .footbutton {
      padding-bottom: 30px;
      .auto {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .rightx {
          // width: 632px;
          // height: 632px;
          background: #effffc;
          border-radius: 50%;
          width: 375px;
          height: 350px;
          position: relative;
          .mox {
            cursor: pointer;
            font-weight: 500;
            color: #fff;
            border-radius: 20px;
            background: #00bf9f;
            position: absolute;
            padding: 10px 15px;
          }
          .onem {
            left: 60px;
          }
          .twom {
            top: 150px;
          }
          .bod {
            width: 70%;
            float: right;
          }
          img {
            // width: 432px;
          }
        }
      }
    }
    .midob {
      background: #fff;
      padding-bottom: 100px;
      h2 {
        padding-top: 100px;
        padding-bottom: 100px;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
  .footer {
    background: #00bf9f;
    padding: 20px 0;
    position: fixed;
    bottom: 00px;
    text-align: center;
    // margin: auto;
    width: 100%;
    span {
      cursor: pointer;
    }
  }
}
</style>